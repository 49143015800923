// eslint-disable-next-line
import React, { useState } from "react";
import HeroSection from "./pages/HeroSection";
import AboutMe1 from "./pages/AboutMe1";
import AboutMe2 from "./pages/AboutMe2";
import ArtGallery from "./pages/ArtGallery";
import ProjectList from "./pages/ProjectList";
import MessageBoard from "./pages/MessageBoard";
import NavBar from "./components/NavBar";
import NavDots from "./components/NavDots";
import Page from "./components/Page";

import "./assets/theme.css";
import "./App.css";
import { Particles } from "@blackbox-vision/react-particles";

function App() {
  // const [activePg, setActivePg] = useState(1);

  return (
    <div className="App">
      <NavBar></NavBar>
      <NavDots></NavDots>
      <ParticlesJs></ParticlesJs>
      <Page num="1" name="hero">
        <HeroSection />
        <img className="arrow" src="/assets/svgs/arrow.svg" alt="arrow" />
      </Page>
      <Page num="2" name="about1">
        <AboutMe1 className="content"></AboutMe1>
      </Page>
      <Page num="3" name="art">
        <ArtGallery className="content"></ArtGallery>
      </Page>
      <Page num="4" name="proj">
        <ProjectList className="content"></ProjectList>
      </Page>
      <Page num="5" name="about2">
        <AboutMe2 className="content"></AboutMe2>
      </Page>
      <Page num="6" name="msgboard">
        <MessageBoard className="content"></MessageBoard>
      </Page>
    </div>
  );
}

const ParticlesJs = () => (
  <Particles
    params={{
      id: "tsparticles",
      fpsLimit: 60,
      fullScreen: {
        enable: true,
      },
      particles: {
        number: {
          value: 80,
          density: {
            enable: true,
            value_area: 800,
          },
        },
        shape: {
          type: "image",
          image: { src: "/assets/svgs/flower.png", height: 100, width: 100 },
          polygon: { nb_sides: 5 },
          stroke: { color: "#000000", width: 0 },
        },
        opacity: {
          value: 0.5,
        },
        size: {
          value: 13,
          random: true,
        },

        move: {
          enable: true,
          speed: 3,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "repulse",
          },
          resize: true,
        },
        modes: {
          repulse: {
            distance: 150,
            duration: 0.3,
          },
        },
      },
      responsive: [
        {
          options: {
            particles: {
              number: { limit: 50 },
            },
          },
        },
      ],
      detectRetina: true,
    }}
  />
);

export default App;
