// eslint-disable-next-line
import React, { Component } from "react";
import "./MessageBoard.css";

function MessageBoard() {
  return (
    <div className="message-board">
      <div className="text box">
        <h1>Thanks for stopping by! :></h1>
      </div>
    </div>
  );
}
export default MessageBoard;
