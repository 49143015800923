// eslint-disable-next-line
import React, { Component } from "react";

function AboutMe2() {
  return (
    <div className="aboutme-2">
      <div className="text box">
        <h1>I also really like bread, climbing, and crocheting!</h1>
      </div>
      <div className="images">
        <div className="stack">
          <img className="circle" src="/assets/photos/bread.png"></img>
          <img className="circle" src="/assets/photos/crochet.png"></img>
        </div>
        <img className="climb" src="/assets/photos/climb.png"></img>
      </div>
    </div>
  );
}
export default AboutMe2;
