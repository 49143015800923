import React, { useState, useCallback } from "react";
import "./ArtGallery.css";
import Gallery from "../components/Gallery.js";

function ArtGallery() {
  const photos = [
    {
      src: "/assets/art/bust.jpg",
    },
    {
      src: "/assets/art/fencing.jpg",
    },
    {
      src: "/assets/art/clarinet.jpg",
    },

    {
      src: "/assets/art/hair2.jpg",
    },
    {
      src: "/assets/art/hair3.jpg",
    },

    {
      src: "/assets/art/hanbok.jpg",
    },
    {
      src: "/assets/art/hair1.jpg",
    },
    {
      src: "/assets/art/jv.jpg",
    },
    {
      src: "/assets/art/kitsune.jpg",
    },
    {
      src: "/assets/art/kt.jpg",
    },
    {
      src: "/assets/art/oMg.jpg",
    },
  ];

  return (
    <div className="artgallery">
      <h1 className="title">ART</h1>
      <Gallery pictures={photos} />
    </div>
  );
}

export default ArtGallery;
