import "./Gallery.css";
import { useState, React } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronLeft,
  faCircleChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

export default function Gallery({ pictures }) {
  const [slideNumber, setSlideNumber] = useState(0);
  const [openPhoto, setOpenPhoto] = useState(false);

  const handleOpenPhoto = (index) => {
    setSlideNumber(index);
    setOpenPhoto(true);
  };

  const handleClosePhoto = () => {
    setOpenPhoto(false);
  };
  const handlePrevPhoto = () => {
    slideNumber === 0
      ? setSlideNumber(pictures.length - 1)
      : setSlideNumber(slideNumber - 1);
  };
  const handleNextPhoto = () => {
    slideNumber === pictures.length - 1
      ? setSlideNumber(0)
      : setSlideNumber(slideNumber + 1);
  };

  return (
    <>
      {openPhoto && (
        <div className="sliderWrap">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose btn"
            onClick={handleClosePhoto}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="btnPrev btn"
            onClick={handlePrevPhoto}
          ></FontAwesomeIcon>
          <FontAwesomeIcon
            icon={faCircleChevronRight}
            className="btnNext btn"
            onClick={handleNextPhoto}
          ></FontAwesomeIcon>
          <div className="fullScreenImage">
            <img src={pictures[slideNumber].src} alt=""></img>
          </div>
        </div>
      )}
      <div className="galleryWrap">
        {pictures &&
          pictures.map((pic, index) => {
            return (
              <div
                className="single"
                key={index}
                onClick={() => handleOpenPhoto(index)}
                // style={{ backgroundImage: pic.src }}
              >
                <img src={pic.src} alt=""></img>
              </div>
            );
          })}
      </div>
    </>
  );
}
