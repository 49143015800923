// eslint-disable-next-line
import React, { Component } from "react";
import "./ProjectList.css";
import Project from "../components/Project.js";

function ProjectList() {
  const projs = [
    {
      id: "bb",
      name: "Baby Beethoven",
      callback: () => {},
    },
    {
      id: "billsplit",
      name: "Bill Splitter",
      callback: () => {
        open(
          "https://play.google.com/store/apps/details?id=com.sachonstudios.billsplitter&hl=en_US&gl=US"
        );
      },
    },
    {
      id: "scan",
      name: "Scan-ulator",
      callback: () => {
        open(
          "https://play.google.com/store/apps/details?id=com.sachonstudios.scanulator&hl=en_US&gl=US"
        );
      },
    },
  ];

  const open = (url) => {
    const win = window.open(url, "_blank");
    if (win != null) {
      win.focus();
    }
  };

  return (
    <div className="projectlist">
      <h1 className="title">&#38; PROJECTS</h1>
      <Project projects={projs} />
    </div>
  );
}

export default ProjectList;
