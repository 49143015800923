import "./NavBar.css";
// eslint-disable-next-line
import React, { Component } from "react";

function NavBar() {
  return (
    <div className="navbar">
      <ul className="socials">
        <li>
          <a href="https://www.instagram.com/hannahkim_art/">
            <img
              src="/assets/svgs/insta.svg"
              style={{ height: 50, width: 50 }}
              alt="instagram"
            ></img>
          </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/hannahkim6">
            <img
              src="/assets/svgs/linkedin.svg"
              style={{ height: 50, width: 50 }}
              alt="linkedin"
            ></img>
          </a>
        </li>
      </ul>
    </div>
  );
}
export default NavBar;
