import "./HeroSection.css";
// eslint-disable-next-line
import React, { Component } from "react";

function HeroSection() {
  return (
    <div className="titleBox box">
      <h1 className="title">HANNAH KIM</h1>
    </div>
  );
}

export default HeroSection;
