import React from "react";

export default function Page(props) {
  return (
    <div className={"page page-" + props.num}>
      <a href={"#" + props.name}></a>
      {props.children}
    </div>
  );
}
