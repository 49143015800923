// eslint-disable-next-line
import React, { Component } from "react";
import "./AboutMe.css";

function AboutMe1() {
  return (
    <div className="aboutme-1">
      <img className="me" src="/assets/photos/me_chicago.jpg"></img>
      <div className="text box">
        <h1>
          Hi, I'm Hannah!<br></br>I like creating things like...
        </h1>
      </div>
    </div>
  );
}
export default AboutMe1;
