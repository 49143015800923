import { useState, React } from "react";
import "./Project.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

export default function Project({ projects }) {
  const [openProject, setOpenProject] = useState(false);

  const handleCloseDialogBox = () => {
    setOpenProject(false);
  };

  const handleClickProject = (e) => {
    projects.forEach((proj) => {
      if (proj.id === e.target.id) {
        proj.callback();
      }
      if (e.target.id === "bb") {
        setOpenProject(true);
      }
    });
  };

  return (
    <>
      {openProject && (
        <div className="projDialogBox box center">
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="btnClose btn"
            onClick={handleCloseDialogBox}
          ></FontAwesomeIcon>
          <h1>Baby Beethoven</h1>
          <h3>
            A classical music-generating AI implemented with an LSTM RNN (Long
            Short-Term Memory Reccurent Neural Network), accelerated on an FPGA.{" "}
            <br />
            Here are some sample audio clips it generated:
          </h3>
          <div className="audio">
            <audio controls>
              <source
                src="/assets/audio/song_36_9.mp3"
                type="audio/mpeg"
              ></source>
            </audio>
            <audio controls>
              <source
                src="/assets/audio/song_36_10.mp3"
                type="audio/mpeg"
              ></source>
            </audio>
            <audio controls>
              <source
                src="/assets/audio/song_36_11.mp3"
                type="audio/mpeg"
              ></source>
            </audio>
            <audio controls>
              <source
                src="/assets/audio/song_36_13.mp3"
                type="audio/mpeg"
              ></source>
            </audio>
          </div>
        </div>
      )}
      <div className="projectWrapper">
        {projects &&
          projects.map((proj, index) => {
            return (
              <div
                className={"box project "}
                id={proj.id}
                key={index}
                onClick={handleClickProject}
              >
                {proj.name}
              </div>
            );
          })}
      </div>
    </>
  );
}
